#pdf-reports {
  .report-type {
    span {
      display: inline-block;
      width: 250px;
    }
  }
  #download-pdf{
    display: flex;
  }
  .download-pdf{
    padding: 10px;
  }
  .processing{
    background: red;
  }
  .done{
    background: green;
    color: #fff;
  }
}
