#transactions {
  span.device {
    padding: 5px;
  }
  .report-type {
    span {
      display: inline-block;
      width: 200px;
    }
  }
}
