#review {
  padding: 10px;
  main {
    p {
      span {
        width: 150px;
        display: inline-block;
        font-weight: 800;
      }
    }
  }
}
