#reports-review {
  .label-wrap{
    display: flex;
    flex-wrap: wrap;
  }
  a.label {
    margin: 2px;
    float: left;
    padding: 2px;
    border: 1px solid red;
    border-radius: 8px;
    color: #000;
    text-decoration: none;
    height: fit-content;
  }
}
